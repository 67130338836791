<template>
  <div id="stats-domain-week">
    <StatsDomainWeek />
  </div>
</template>

<script>
import StatsDomainWeek from '../../../components/stats/domain/StatsDomainWeek'

export default {
  components: {
    StatsDomainWeek
  }
}
</script>
